import React, { Component } from "react";
import PortfolioGalleryPopupRoyalQuest
    from "../../../elements/portfolio/PortfolioGalleryPopupRoyalQuest";
import {FiChevronsRight} from "react-icons/all";

class AboutRoyalQuest extends Component{
    render(){
        let title = 'Royal Quest',
        description = 'The MMORPG Royal Quest events take place in the unique world of Aura where magic, technology, and alchemy coexist. Aura faces danger from black alchemists who seek to obtain the extremely rare and valuable mineral, elenium, which has unique properties. To defend against enemies, the king is searching for brave heroes who can banish the invaders forever. In reward, the king offers heroes titles, castles, and estates! Those who want to become lords and own their own castle will have to undergo many tough trials. On their journey, players will face dwellers of dark forests and endless deserts, underground inhabitants and sea monsters, swamp creatures, geometers, orcs, demons, and numerous hordes of undead.',
        description2 = '';

        return(
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-flex-start">
                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/about_katauri_royal_quest.jpg" alt="Royal Quest Game"/>
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        {/*<div className="icon">*/}
                                        {/*</div>*/}
                                        <h2 className="title">{title}</h2>
                                        <div className='description2'>
                                            <div className='item'>
                                                <div className='item-title'>Year:</div>
                                                <div>2011</div>
                                            </div>
                                            <div className='item'>
                                                <div className='item-title'>Platform:</div>
                                                <div>PC</div>
                                            </div>
                                            <div className='item'>
                                                <div className='item-title'>Publisher:</div>
                                                <div>Fulqrum Publishing Ltd.</div>
                                            </div>
                                            <div className='item'>
                                                <div className='item-title'>Genre:</div>
                                                <div>Massive Multiplayer Online Role Playing Game</div>
                                            </div>
                                            {/*<div className='item'>*/}
                                            {/*    <div className='item-title'>Ratings:</div>*/}
                                            {/*    <div>*/}
                                            {/*        <img width="20px" src="/assets/images/icons/ico-metacritic.png"*/}
                                            {/*             alt="metacritic logo"/>*/}
                                            {/*        <span>Metascore 78 / 100</span>*/}
                                            {/*        <div>User Score 8.8 / 10</div>*/}
                                            {/*        <div>GameRankings 76%</div>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                            {/*<div className='item'>*/}
                                            {/*    <div className='item-title'>Awards:</div>*/}
                                            {/*    <div>*/}
                                            {/*        <img width="20px" src="/assets/images/icons/ico-cup.png" alt="cup"/>*/}
                                            {/*        <span>#54 Best PC Game of 2010</span>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                        </div>
                                        <p className="description">{description}</p>
                                        <p className="description">{description2}</p>
                                        <div className="purchase-btn mb--30">
                                            <a href="https://royalquest.com/ru/games/Royal_Quest"
                                               className="btn-transparent" target="_blank" rel="noopener noreferrer">Royal Quest game website <FiChevronsRight/></a>
                                        </div>
                                        <div className="videos mb--30">
                                            <div className="video">
                                                <iframe width="560" height="315"
                                                        src="https://www.youtube.com/embed/uEO4qB2Zztc"
                                                        title="YouTube video player" frameBorder="0"
                                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                        allowFullScreen></iframe>
                                            </div>
                                        </div>
                                        <PortfolioGalleryPopupRoyalQuest/>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default AboutRoyalQuest;