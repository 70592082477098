import React, { Component } from "react";
import {FiChevronsRight} from "react-icons/all";

class AboutTastyWay extends Component{
    render(){
        let title = 'Tasty Way',
        description = 'Tasty Way — игра для iPhone/iPod Touch.',
        description2 = 'Нямстеры возвращаются в новой игре Tasty Way для всей семьи! Вас ждет бесконечная гонка за фруктами и бонусами под веселую музыку. Уникальное управление и постоянное нарастание темпа игры не позволит скучать ни минуты!';

        return(
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/about_kranx_tasty_way.jpg" alt="Tasty Way Game"/>
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        {/*<div className="icon">*/}
                                        {/*</div>*/}
                                        <h2 className="title">{title}</h2>
                                        <p className="description">{description}</p>
                                        <p className="description">{description2}</p>
                                        {/*<div className="purchase-btn">
                                            <a href="https://royalquest.ru/" className="btn-transparent" target="_blank" rel="noopener noreferrer">Официальный сайт игры <FiChevronsRight/></a>

                                        </div>*/}
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default AboutTastyWay;