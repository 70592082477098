import React, { Component } from "react";
import {FiChevronsRight} from "react-icons/all";

class AboutKatauri extends Component{
    render(){
        let title = 'Katauri Interactive',
        description = '',
        description2 = '';

        return(
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/about_katauri.jpg" alt="About Katauri"/>
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        {/*<div className="icon">*/}
                                        {/*</div>*/}
                                        <h2 className="title">{title}</h2>
                                        <p className="description">
                                            <b>Katauri Interactive</b> is a highly regarded company in the computer game development industry. It was established in Vladivostok in 2004 by <a className="" href="https://ru.wikipedia.org/wiki/%D0%93%D1%83%D1%81%D0%B0%D1%80%D0%BE%D0%B2,_%D0%94%D0%BC%D0%B8%D1%82%D1%80%D0%B8%D0%B9_%D0%92%D0%BB%D0%B0%D0%B4%D0%B8%D0%BC%D0%B8%D1%80%D0%BE%D0%B2%D0%B8%D1%87" target="_blank" rel="noopener noreferrer">Dmitry Gusarov</a> and Alexander Yazyinin, with roots tracing back to Newgame Software (2002) and Elemental Games (1999). Our talented team created the famous game series Space Rangers and King's Bounty, which received high ratings globally and were among the top PC games of different years. In 2012, Katauri Interactive merged with KranX Productions to form a new company called <b>Neodinamika</b>.
                                        </p>
                                        <p className="description">
                                            Contact Us:  <a href="mailto:mail@katauri.com">mail@katauri.com</a>
                                        </p>
                                        <div className="purchase-btn mb--30 mt--30">
                                            <a href="https://ru.wikipedia.org/wiki/Katauri_Interactive"
                                               className="btn-transparent" target="_blank" rel="noopener noreferrer">Description on Wikipedia <FiChevronsRight/></a>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default AboutKatauri;