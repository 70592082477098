import React, { Component } from "react";
import PortfolioGalleryPopupKingsBountyLegions
    from "../../../elements/portfolio/PortfolioGalleryPopupKingsBountyLegions";
import {FiChevronsRight} from "react-icons/all";

class AboutKingsBountyLegions extends Component{
    render(){
        let title = "King's Bounty: Legions",
        description = 'King\'s Bounty: Legions made history as the first 3D strategy game to be launched in social networks using the Free2Play model in 2011. It was available on Facebook, as well as other platforms including mobile. The game was demonstrated as the most technologically advanced cross-platform game at the Unity and Facebook partnership announcement in 2013, with its outstanding graphics and immersive gameplay. This allowed players to dive into the rich King\'s Bounty universe, developed by Katauri Interactive. In King\'s Bounty: Legions, players could engage in intense PvP battles with their friends for the first time.',
        description2 = '';

        return(
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-flex-start">
                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/about_kranx_kings_bounty_legions.jpg" alt="Kings Bounty Legions Game"/>
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        {/*<div className="icon">*/}
                                        {/*</div>*/}
                                        <h2 className="title">{title}</h2>
                                        <div className='description2'>
                                            <div className='item'>
                                                <div className='item-title'>Year:</div>
                                                <div>2011</div>
                                            </div>
                                            <div className='item'>
                                                <div className='item-title'>Platform:</div>
                                                <div>Facebook, VK, OK, Kongregate, Steam, Mobile</div>
                                            </div>
                                            <div className='item'>
                                                <div className='item-title'>Genre:</div>
                                                <div>Online Turn-Based Strategy</div>
                                            </div>
                                            <div className='item'>
                                                <div className='item-title'>Awards:</div>
                                                <div>
                                                    <img width="" src="/assets/images/icons/rewards_kings_bounty_legions.jpg" alt="cup"/>
                                                </div>
                                            </div>
                                        </div>

                                        <p className="description">{description}</p>
                                        <p className="description">{description2}</p>
                                        <div className="purchase-btn mb--30">
                                            <a href="https://ru.wikipedia.org/wiki/King%E2%80%99s_Bounty:_Legions"
                                               className="btn-transparent" target="_blank" rel="noopener noreferrer">Description on Wikipedia <FiChevronsRight/></a>
                                        </div>
                                        <div className="videos">
                                            <div className="video">
                                                <iframe width="" height="100%"
                                                        src="https://www.youtube.com/embed/ylygQHJsxlE"
                                                        title="YouTube video player" frameBorder="0"
                                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                        allowFullScreen></iframe>
                                            </div>
                                        </div>
                                        <PortfolioGalleryPopupKingsBountyLegions/>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default AboutKingsBountyLegions;