import React, { Component } from "react";
import PortfolioGalleryPopupBashenki from "../../../elements/portfolio/PortfolioGalleryPopupBashenki";
import {FiChevronsRight} from "react-icons/all";

class AboutBashenki extends Component{
    render(){
        let title = 'Башенки',
        description = 'Monster Mash (“Башенки”) - игра в жанре Tower Defense, была разработана совместно со студией Arise. Легионы злобных и причудливых монстров атакуют мирных деревенских жителей. Хорошо сбалансированная игра с милой анимацией и увлекательным геймплеем нашла много поклонников по всему миру. Была издана компаниями Big Fish Games и NevoSoft.',
        description2 = '';

        return(
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-flex-start">
                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/about_kranx_bashenki.jpg" alt="Bashenki Game"/>
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        {/*<div className="icon">*/}
                                        {/*</div>*/}
                                        <h2 className="title">{title}</h2>
                                        <div className='description2'>
                                            <div className='item'>
                                                <div className='item-title'>Год:</div>
                                                <div>2008</div>
                                            </div>
                                            <div className='item'>
                                                <div className='item-title'>Платформа:</div>
                                                <div>PC</div>
                                            </div>
                                            <div className='item'>
                                                <div className='item-title'>Жанр:</div>
                                                <div>Tower Defense Arcade</div>
                                            </div>
                                        </div>
                                        <p className="description">{description}</p>
                                        {/*<p className="description">{description2}</p>*/}
                                        {/*/!*<div className="purchase-btn mb--30">*/}
                                        {/*    <a href="https://royalquest.ru/" className="btn-transparent" target="_blank" rel="noopener noreferrer">Официальный сайт игры <FiChevronsRight/></a>*/}
                                        {/*</div>*!/*/}

                                        <PortfolioGalleryPopupBashenki/>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default AboutBashenki;