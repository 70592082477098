import React, { Component } from "react";

class AboutKranx extends Component{
    render(){
        let title = 'KranX Productions',
        description = '',
        description2 = '';

        return(
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/about_kranx.jpg" alt="About Kranx"/>
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        {/*<div className="icon">*/}
                                        {/*</div>*/}
                                        <h2 className="title">{title}</h2>
                                        <p className="description">
                                            <b>KranX Productions</b> was founded in 2004 together with the 1C company by the leader, leading game designer and programmer of the Kaliningrad studio <i>"K-D LAB"</i>, Andrey Kuzmin. He was the one who led the development of the legendary games <i>"Vangers"</i> and <i>"Perimeter"</i>, and also created the unique game universe (<i>Psychosphere</i>) presented in the games. KranX became the first Russian production company and was initially focused on external management of multiple projects, helping other teams bring them to release. Parallel to this, KranX prototyped many original game concepts, exploring different platforms and business models. Several hits were released in the casual genre (Yumsters, Musaic Box), and then for a long time the company was focused on the development of the cross-platform online strategy "King's Bounty: Legions" in collaboration with Katauri Interactive, with which it later formed <b>Neodinamika</b>.
                                        </p>
                                        {/*<p className="description">{description}</p>*/}
                                        {/*<p className="description">{description2}</p>*/}
                                        {/*<div className="purchase-btn">*/}
                                        {/*    <a href="https://kranx.com/" className="btn-transparent" target="_blank" rel="noopener noreferrer">Официальный сайт</a>*/}
                                        {/*</div>*/}
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default AboutKranx;