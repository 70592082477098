import React, { Component } from "react";
import PortfolioGalleryPopupKingsBountyPrincess
    from "../../../elements/portfolio/PortfolioGalleryPopupKingsBountyPrincess";
import {FiChevronsRight} from "react-icons/all";


class AboutKingsBountyPrincess extends Component{
    render(){
        let title = 'King\'s Bounty: Armored Princess',
        description = 'A continuation of the adventure tactical strategy "King\'s Bounty: The Legend", in which the player takes on the role of Princess Amelie and sets off into the world of Teana to find the famous knight and her mentor Bill Gilbert. The heroine must overcome all the dangers of the unknown world and return home to defend her home capital in a battle against a huge army of demons that have besieged the city.',
        description2 = 'Amelie\'s diverse abilities and the option of free flight will assist her in overcoming obstacles, with a loyal dragon by her side. The dragon will be using simple strikes, intense combat, and volcanic awakenings to fiercely defend the princess in battles, and also lend a hand in treasure hunting.';

        return(
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-flex-start">
                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/about_katauri_kings_bounty_princess.jpg" alt="King`s Bounty Princess Game"/>
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        {/*<div className="icon">*/}
                                        {/*</div>*/}
                                        <h2 className="title">{title}</h2>
                                        <div className='description2'>
                                            <div className='item'>
                                                <div className='item-title'>Year:</div>
                                                <div>2009</div>
                                            </div>
                                            <div className='item'>
                                                <div className='item-title'>Platform:</div>
                                                <div>PC, Steam</div>
                                            </div>
                                            <div className='item'>
                                                <div className='item-title'>Publisher:</div>
                                                <div>ValuSoft , 1C Entertainment</div>
                                            </div>
                                            <div className='item'>
                                                <div className='item-title'>Genre:</div>
                                                <div>Tactical role-playing game</div>
                                            </div>
                                            <div className='item'>
                                                <div className='item-title'>Ratings:</div>
                                                <div>
                                                    <img width="20px" src="/assets/images/icons/ico-metacritic.png"
                                                         alt="metacritic logo"/>
                                                    <span>Metascore 82 / 100</span>
                                                    <div>User Score 8.6 / 10</div>
                                                    <div>GameRankings 81%</div>
                                                </div>
                                            </div>
                                            <div className='item'>
                                                <div className='item-title'>Awards:</div>
                                                <div>
                                                    <img width="20px" src="/assets/images/icons/ico-cup.png" alt="cup"/>
                                                    <span>#28 Best PC Game of 2009</span>
                                                </div>
                                            </div>
                                        </div>
                                        <p className="description">{description}</p>
                                        <p className="description">{description2}</p>

                                        <div className="purchase-btn mb--30">
                                            <a href="https://store.steampowered.com/app/3170/Kings_Bounty_Armored_Princess"
                                               className="btn-transparent" target="_blank" rel="noopener noreferrer">Available on STEAM <FiChevronsRight/></a>
                                        </div>
                                        <div className="purchase-btn mb--30">
                                            <a href="https://ru.wikipedia.org/wiki/King%E2%80%99s_Bounty:_%D0%9F%D1%80%D0%B8%D0%BD%D1%86%D0%B5%D1%81%D1%81%D0%B0_%D0%B2_%D0%B4%D0%BE%D1%81%D0%BF%D0%B5%D1%85%D0%B0%D1%85"
                                               className="btn-transparent" target="_blank" rel="noopener noreferrer">Description on Wikipedia <FiChevronsRight/></a>
                                        </div>
                                        <div className="videos mb--30">
                                            <div className="video">
                                                <video controls
                                                       src="https://cdn.akamai.steamstatic.com/steam/apps/2031685/movie480.webm?t=1447361150"
                                                       poster="https://imgs.sector.sk/files/novinky/2009-8-27-14-53-89/kb%202009-08-27%2014-20-45-69.jpg"
                                                />
                                            </div>
                                        </div>
                                        <PortfolioGalleryPopupKingsBountyPrincess/>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default AboutKingsBountyPrincess;