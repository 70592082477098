import React, { Component } from "react";
import {FiChevronsRight} from "react-icons/all";

class AboutLittleBigSnake extends Component{
    render(){
        let title = 'Little Big Snake',
        description = 'Little Big Snake was fully developed by the Neodinamika team and was launched in browser format in early 2018. Within the first few months, it attracted several million players worldwide without any advertising, only through word of mouth.Six months later, the American company Addicting Games (which became part of Enthusiast Gaming in 2021) became the publisher. A year later, the game was ported to Unity 3D and was released on mobile devices (iOS and Android). Neodinamika continues to develop the game, adding new and interesting features to this day.',
        description2 = 'The Little Big Snake game is highly regarded as a standout among its peers and a global success in its genre, boasting a gameplay that builds upon the popular slither mechanics with a wealth of unique features and enhancements.The game showcases a wide variety of vibrant and animated snake skins, thrilling group gameplay, the pursuit of Rebels, intriguing dungeons, upgradable pets, and so much more. The level of balance development in Little Big Snake is comparable to that of cyber-sports games.';

        return(
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-flex-start">
                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/about_neodinamika_little_big_snake.jpg" alt="Little Big Snake Game"/>
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        {/*<div className="icon">*/}
                                        {/*</div>*/}
                                        <h2 className="title">{title}</h2>
                                        <div className='description2'>
                                            <div className='item'>
                                                <div className='item-title'>Year:</div>
                                                <div>2018</div>
                                            </div>
                                            <div className='item'>
                                                <div className='item-title'>Platform:</div>
                                                <div>Web, Mobile (iOS/Android)</div>
                                            </div>
                                            <div className='item'>
                                                <div className='item-title'>Genre:</div>
                                                <div>Multiplayer Slither Action (IO Game)</div>
                                            </div>
                                            <div className='item'>
                                                <div className='item-title'>Audience:</div>
                                                <div>Over 50 million</div>
                                            </div>
                                            {/*<div className='item'>*/}
                                            {/*    <div className='item-title'>Metacritic:</div>*/}
                                            {/*    <div>9999</div>*/}
                                            {/*</div>*/}
                                            {/*<div className='item'>*/}
                                            {/*    <div className='item-title'>Awards:</div>*/}
                                            {/*    <div>9999</div>*/}
                                            {/*</div>*/}
                                        </div>
                                        <p className="description">{description}</p>
                                        <p className="description">{description2}</p>

                                        <div className="purchase-btn mb--30">
                                            <a href="https://littlebigsnake.com/" className="btn-transparent" target="_blank" rel="noopener noreferrer">Little Big Snake game website <FiChevronsRight/></a>
                                        </div>
                                        <div className="videos">
                                            <div className="video">
                                                <iframe width="" height="100%"
                                                        src="https://www.youtube.com/embed/7rKVlAkRDKc"
                                                        title="YouTube video player" frameBorder="0"
                                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                        allowFullScreen></iframe>
                                            </div>
                                            <div className="video">
                                                <iframe width="560" height="315"
                                                        src="https://www.youtube.com/embed/y4oOlnXjJA8"
                                                        title="YouTube video player" frameBorder="0"
                                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                        allowFullScreen></iframe>
                                            </div>
                                            <div className="video">
                                                <iframe width="560" height="315"
                                                        src="https://www.youtube.com/embed/081vHhM1lVw"
                                                        title="YouTube video player" frameBorder="0"
                                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                        allowFullScreen></iframe>
                                            </div>
                                        </div>
                                        <div className="purchase-btn mt--30 mb--30">
                                            <a href="https://www.youtube.com/c/SerkanAy/videos" className="btn-transparent" target="_blank" rel="noopener noreferrer">More videos</a>
                                        </div>
                                        
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default AboutLittleBigSnake;