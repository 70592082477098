import React, { Component } from "react";
import PortfolioGalleryPopupVangers from "../../../elements/portfolio/PortfolioGalleryPopupVangers";
import {FiChevronsRight} from "react-icons/all";

class AboutVangers extends Component{
    render(){
        let title = 'Vangers',
        description = '"Vangers: One For The Road" is a cult game released in 1998 by K-D LAB, Kaliningrad under the leadership of Andrey Kuzmin as the author of the original universe and musical themes, and as the main developer of the Surmap technology and game logic. The game was published by Buka (Russia) and Interactive Magic (rest of the world). In 2014, the rights were transferred to KranX Productions, and a re-release was made on Steam/GOG, including an updated version for Linux, macOS, and Windows. In 2020-2022, the game was ported to mobile devices. Currently, development is underway on an open-source version by the Vangers Community. Over 20 years after its release, Vangers has been given a second life due to reviews by popular video bloggers, who have garnered millions of views and new fans of this unique game artifact.',
        description2 = '';

        return(
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-flex-start">
                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/about_kranx_vangers.jpg" alt="Vangers Game"/>
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        {/*<div className="icon">*/}
                                        {/*</div>*/}
                                        <h2 className="title">{title}</h2>
                                        <div className='description2'>
                                            <div className='item'>
                                                <div className='item-title'>Year:</div>
                                                <div>1998, 2014</div>
                                            </div>
                                            <div className='item'>
                                                <div className='item-title'>Platform:</div>
                                                <div>PC, Mobile, Steam</div>
                                            </div>
                                            <div className='item'>
                                                <div className='item-title'>Genre:</div>
                                                <div>Action Adventure Crazy Mix</div>
                                            </div>
                                            <div className='item'>
                                                <div className='item-title'>Awards:</div>
                                                <div>
                                                    <div>Absolute Games: Rated a "Masterpiece"</div>
                                                    <div>Steam Reviews: Overwhelmingly Positive</div>
                                                </div>
                                            </div>
                                        </div>

                                        <p className="description">{description}</p>
                                        <div className="purchase-btn mb--30">
                                            <a href="https://vangers.ru/"
                                               className="btn-transparent" target="_blank" rel="noopener noreferrer">Vangers game website <FiChevronsRight/></a>
                                        </div>
                                        <div className="purchase-btn mb--30">
                                            <a href="https://store.steampowered.com/app/264080/Vangers/"
                                               className="btn-transparent" target="_blank" rel="noopener noreferrer">Available on STEAM <FiChevronsRight/></a>
                                        </div>
                                        <div className="purchase-btn mb--30">
                                            <a href="https://ru.wikipedia.org/wiki/%D0%92%D0%B0%D0%BD%D0%B3%D0%B5%D1%80%D1%8B"
                                               className="btn-transparent" target="_blank" rel="noopener noreferrer">Description on Wikipedia <FiChevronsRight/></a>
                                        </div>
                                        <div className="videos">
                                            <div className="video">
                                                <iframe width="" height="100%"
                                                        src="https://www.youtube.com/embed/7smUD83nb_8"
                                                        title="YouTube video player" frameBorder="0"
                                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                        allowFullScreen></iframe>
                                            </div>
                                            <div className="video">
                                                <iframe width="560" height="315"
                                                        src="https://www.youtube.com/embed/Q85UNox8i0o"
                                                        title="YouTube video player" frameBorder="0"
                                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                        allowFullScreen></iframe>
                                            </div>
                                            <div className="video">
                                                <iframe width="560" height="315"
                                                        src="https://www.youtube.com/embed/P9R7QJ5Sh1o"
                                                        title="YouTube video player" frameBorder="0"
                                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                        allowFullScreen></iframe>
                                            </div>
                                        </div>
                                        <PortfolioGalleryPopupVangers/>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default AboutVangers;