import React from 'react';
// import {FaTwitter ,FaInstagram ,FaFacebookF , FaLinkedinIn} from "react-icons/fa";

// const SocialShare = [
//     {Social: <FaFacebookF /> , link: 'https://www.facebook.com/'},
//     {Social: <FaLinkedinIn /> , link: 'https://www.linkedin.com/'},
//     {Social: <FaInstagram /> , link: 'https://www.instagram.com/'},
//     {Social: <FaTwitter /> , link: 'https://twitter.com/'},
// ]

const FooterThree = () => {
    return (
        <div className="footer-style-3 pt--100 pb--30 bg_color--6">
            <div className="wrapper">
                <div className="row align-items-center justify-content-between">
                    <div className="col-lg-12">
                        <div className="inner text-center">
                            <div className="callto-action-area">
                                <h2 className="title text-white mb--25">Cooperate with us!</h2>
                                <p className="description  text-white">We have two new game projects in development and
                                    welcome you to become our partner and investor by reaching out to us.</p>

                                <div className="contact-btn">
                                    <a className="btn-default" href="/#contact">Contact Us</a>
                                </div>

                            </div>
                            <div className="logo">
                                <a href="/">
                                    <img src="/assets/images/logo/logo-light.png" alt="Logo Neodinamika"/>
                                </a>
                            </div>
                            {/*<ul className="social-share d-flex justify-content-center liststyle">*/}
                            {/*    {SocialShare.map((val , i) => (*/}
                            {/*        <li key={i}><a href={`${val.link}`}>{val.Social}</a></li>*/}
                            {/*    ))}*/}
                            {/*</ul>*/}
                            <div className="text mt--20">
                                <p>LLC NeoDinamika, TIN: 3906219044, PSRN: 1103925015930
                                </p>
                                <p>Building 7, liter B, Schiller Street, Kaliningrad, Kaliningrad Oblast, 236022, Russia
                                </p>
                                <p>All rights reserved © 2023 Neodinamika</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default FooterThree;