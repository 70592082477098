import React, { Component } from "react";
import PortfolioGalleryPopupYamsters from "../../../elements/portfolio/PortfolioGalleryPopupYamsters";
import {FiChevronsRight} from "react-icons/all";

class AboutYamsters extends Component{
    render(){
        let title = 'Yamsters!',
        description = 'Yumsters! is a series of original casual games by KranX. The games are based on an innovative gameplay principle and are aimed at a broad audience. The games were published by 1C, Big Fish Games, G5 Entertainment, NevoSoft, and HeroCraft for different platforms.',
        description2 = 'In Yumsters, players control funny creatures living in mushrooms. The objective is to manage the Yumsters in an efficient manner while they feed on fruits. This involves pulling the creatures out of their mushrooms and attaching them to the fruits while avoiding pests, utilizing bonuses, and keeping the Yumsters entertained. The game challenges players to master the parallel optimal management of multiple geometric agents.';

        return(
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-flex-start">
                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/about_kranx_yamsters.jpg" alt="Yamsters Game"/>
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        {/*<div className="icon">*/}
                                        {/*</div>*/}
                                        <h2 className="title">{title}</h2>
                                        <div className='description2'>
                                            <div className='item'>
                                                <div className='item-title'>Year:</div>
                                                <div>2007-2009</div>
                                            </div>
                                            <div className='item'>
                                                <div className='item-title'>Platform:</div>
                                                <div>PC, Mobile, Steam</div>
                                            </div>
                                            <div className='item'>
                                                <div className='item-title'>Genre:</div>
                                                <div>Casual Puzzle Arcade</div>
                                            </div>
                                            <div className='item'>
                                                <div className='item-title'>Awards:</div>
                                                <div>
                                                    <div>KRI Awards 2007: "Best Game Design"</div>
                                                    <img width="" src="/assets/images/icons/rewards_yumsters.jpg" alt="cup"/>
                                                </div>
                                            </div>
                                        </div>
                                        <p className="description">{description}</p>
                                        <p className="description">{description2}</p>
                                       {/* <div className="purchase-btn">
                                            <a href="https://royalquest.ru/" className="btn-transparent" target="_blank" rel="noopener noreferrer">Официальный сайт игры <FiChevronsRight/></a>

                                        </div>*/}
                                        <PortfolioGalleryPopupYamsters/>
                                        <div className="mt--50">
                                            <h3 className="mb--30">Reviews</h3>
                                            <div className="quotes">
                                                <figure className="quote">
                                                    <blockquote>
                                                        "It’s a winner! I wouldn’t give a second thought, it’s a solid puzzle with time management. Two genres mixed into one!"
                                                    </blockquote>
                                                    <figcaption>
                                                        &mdash; <cite>TheAppera.com</cite></figcaption>
                                                </figure>
                                                <figure className="quote">
                                                    <blockquote>
                                                        "Grab it!"
                                                    </blockquote>
                                                    <figcaption>
                                                        &mdash; <cite>TouchMyApps.com</cite></figcaption>
                                                </figure>
                                                <figure className="quote">
                                                    <blockquote>
                                                        "This is one of those rare puzzle games that's super easy to pick but virtually impossible to put down."
                                                    </blockquote>
                                                    <figcaption>
                                                        &mdash; <cite>GameZebo.com about PC version - The winner of GameZebo's Best of 2008!</cite></figcaption>
                                                </figure>
                                                <figure className="quote">
                                                    <blockquote>
                                                        "Surprisingly fun gameplay for what initially appears as yet another matching puzzle game. The game makes great use of the touch controls, and the learning curve is just right"
                                                    </blockquote>
                                                    <figcaption>
                                                        &mdash; <cite>www.TouchGen.com</cite></figcaption>
                                                </figure>
                                                <figure className="quote">
                                                    <blockquote>
                                                        "Cute, colorful, addicting, and simple."
                                                    </blockquote>
                                                    <figcaption>
                                                        &mdash; <cite>AppAdvice.com</cite></figcaption>
                                                </figure>
                                            </div>

                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default AboutYamsters;