import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import AboutCompMusicWars from "../component/HomeLayout/homeOne/AboutMusicWars";
import FooterThree from "../component/footer/FooterThree";
import BreadcrumbNeodinamika from "./common/BreadcrumbNeodinamika";

class MusicWars extends Component{
    render(){
        return(
            <React.Fragment>
                {/*было About*/}
                <PageHelmet pageTitle='Music Wars' />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                {/* Start Breadcrump Area */}
                <BreadcrumbNeodinamika title={'Music Wars'}   />
                {/* End Breadcrump Area */}

                {/* Start About Area */}
                <div className="about-area ptb--80 bg_color--1">
                    <AboutCompMusicWars />
                </div>
                {/* End About Area */}

                {/* Start Finding Us Area  */}
                <div className="rn-finding-us-area attacment-fixed rn-finding-us ptb--80 bg_color--1 bg_image bg_image--18" data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 offset-lg-2">
                                <div className="inner">
                                    <div className="content-wrapper">
                                        <div className="content">
                                            <h4 className="theme-gradient">Try it yourself</h4>
                                            <p>As the Sound City sale draws to a close, exclusive gifts await — containing all the springtime artifacts, including the coveted Cryonizer! Don't miss out on this one-of-a-kind opportunity to acquire the most valuable artifacts from the May sale.</p>
                                            <a className="btn-default" href="https://musicwars.ru/" target="_blank" rel="noopener noreferrer">Play</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Finding Us Area  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                {/*<Footer />*/}
                <FooterThree />

            </React.Fragment>
        )
    }
}
export default MusicWars
