import React, { Component } from "react";
import PortfolioGalleryPopupHammerfight from "../../../elements/portfolio/PortfolioGalleryPopupHammerfight";
import {FiChevronsRight} from "react-icons/all";

class AboutHammerfight extends Component{
    render(){
        let title = 'Hammerfight',
        description = 'Hammerfight (previously named Hammerfall) is a cult indie game developed by Russian independent developer Konstantin Koshutin and published by KranX Productions. It is a 2D arcade game entirely built on physics, featuring battles on flying machines using various cutting and striking weapons. The game is set in the universe described in the game "Vangers".',
        description2 = '';

        return(
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-flex-start">
                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/about_kranx_hammerfight.jpg" alt="Hammerfight Game"/>
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        {/*<div className="icon">*/}
                                        {/*</div>*/}
                                        <h2 className="title">{title}</h2>
                                        <div className='description2'>
                                            <div className='item'>
                                                <div className='item-title'>Year:</div>
                                                <div>2009</div>
                                            </div>
                                            <div className='item'>
                                                <div className='item-title'>Platform:</div>
                                                <div>PC, Steam</div>
                                            </div>
                                            <div className='item'>
                                                <div className='item-title'>Genre:</div>
                                                <div>Indie Arcade</div>
                                            </div>
                                            <div className='item'>
                                                <div className='item-title'>Awards:</div>
                                                <div>
                                                    <div>GDC Independent Games Festival Award 2008: "Seumas McNally Grand Prize" and "Excellence in Visual Art" nominations</div>

                                                </div>
                                            </div>

                                        </div>
                                        <p className="description">{description}</p>
                                        {/*<p className="description">{description2}</p>*/}
                                        <div className="purchase-btn mb--30">
                                            <a href="https://store.steampowered.com/app/41100/Hammerfight/"
                                               className="btn-transparent" target="_blank" rel="noopener noreferrer">Available on STEAM <FiChevronsRight/></a>
                                        </div>
                                        <div className="purchase-btn mb--30">
                                            <a href="https://ru.wikipedia.org/wiki/Hammerfight"
                                               className="btn-transparent" target="_blank" rel="noopener noreferrer">Description on Wikipedia <FiChevronsRight/></a>
                                        </div>
                                        <div className="videos mb--30">
                                            <div className="video">
                                                <video controls
                                                       src="https://cdn.cloudflare.steamstatic.com/steam/apps/2033947/movie480.webm?t=1574977940"
                                                       poster=""
                                                />
                                            </div>
                                        </div>
                                        <PortfolioGalleryPopupHammerfight/>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default AboutHammerfight;