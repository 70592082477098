import React, {Component} from "react";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';


// const PortfolioList = [
//     {
//         image: 'image-1',
//         category: 'Development',
//         title: 'Getting tickets to the big show'
//     },
//     {
//         image: 'image-2',
//         category: 'html',
//         title: 'Getting tickets to the big show'
//     },
//     {
//         image: 'image-3',
//         category: 'Development',
//         title: 'Getting tickets to the big show'
//     },
//     {
//         image: 'image-4',
//         category: 'html',
//         title: 'Getting tickets to the big show'
//     },
//     {
//         image: 'image-5',
//         category: 'Development',
//         title: 'Getting tickets to the big show'
//     },
//     {
//         image: 'image-6',
//         category: 'Development',
//         title: 'Getting tickets to the big show'
//     },
//     {
//         image: 'image-7',
//         category: 'Development',
//         title: 'Getting tickets to the big show'
//     },
//     {
//         image: 'image-8',
//         category: 'Development',
//         title: 'Getting tickets to the big show'
//     },
// ]


const images = [
    '/assets/images/portfolio-Musaic/portfolio_musaic_1.jpeg',
    '/assets/images/portfolio-Musaic/portfolio_musaic_2.jpeg',
    '/assets/images/portfolio-Musaic/portfolio_musaic_3.jpeg',
    '/assets/images/portfolio-Musaic/portfolio_musaic_4.jpeg',
    '/assets/images/portfolio-Musaic/portfolio_musaic_5.jpeg',
    '/assets/images/portfolio-Musaic/portfolio_musaic_6.jpeg',
];

class PortfolioGalleryPopupMusaic extends Component {
    constructor(props) {
        super(props);
        this.state = {
            photoIndex: 0,
            isOpen: false,
        };
    }

    render() {
        // const {column, item} = this.props;
        // const list = PortfolioList.slice(0, item);
        const {photoIndex, isOpen} = this.state;

        return (
            <div className='my-3'>
                {/*<h1 className='text-middle mt-4'>Photos</h1>*/}
                <div className='row' style={{margin: '0', gap: '15px'}}>
                    <div className='col' style={{display: 'flex', flexDirection: 'column',gap: '15px', padding: '0'}}>
                        <img
                            src='/assets/images/portfolio-Musaic/portfolio_musaic_1.jpeg'
                            onClick={() => this.setState({isOpen: true, photoIndex: 0})} alt="screenshot"/>
                        <img
                            src='/assets/images/portfolio-Musaic/portfolio_musaic_2.jpeg'
                            onClick={() => this.setState({isOpen: true, photoIndex: 1})} alt="screenshot"/>
                        <img
                            src='/assets/images/portfolio-Musaic/portfolio_musaic_3.jpeg'
                            onClick={() => this.setState({isOpen: true, photoIndex: 2})} alt="screenshot"/>

                    </div>
                    <div className='col' style={{display: 'flex', flexDirection: 'column',gap: '15px', padding: '0'}}>
                        <img
                            src='/assets/images/portfolio-Musaic/portfolio_musaic_4.jpeg'
                            onClick={() => this.setState({isOpen: true, photoIndex: 3})} alt="screenshot"/>
                        <img
                            src='/assets/images/portfolio-Musaic/portfolio_musaic_5.jpeg'
                            onClick={() => this.setState({isOpen: true, photoIndex: 4})} alt="screenshot"/>
                        <img
                            src='/assets/images/portfolio-Musaic/portfolio_musaic_6.jpeg'
                            onClick={() => this.setState({isOpen: true, photoIndex: 5})} alt="screenshot"/>

                    </div>
                </div>

                {isOpen && (
                    <Lightbox
                        mainSrc={images[photoIndex]}
                        nextSrc={images[(photoIndex + 1) % images.length]}
                        prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                        onCloseRequest={() => this.setState({isOpen: false})}
                        onMovePrevRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + images.length - 1) % images.length,
                            })
                        }
                        onMoveNextRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + 1) % images.length,
                            })
                        }
                    />
                )}
            </div>
        )
    }
}

export default PortfolioGalleryPopupMusaic;