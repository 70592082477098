import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
class Testimonial extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-12">
                        <Tabs>
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <TabPanel>
                                        <div className="rn-testimonial-content text-left">
                                            <div className="inner">
                                                <p>Супер игра , особенно игра в командах, играем с племянниками и радуемся, спасибо разработчикам</p>
                                            </div>
                                            <div className="author-info">
                                                <h6><span>Lavanda </span> - отзыв с Google Play</h6>
                                            </div>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                        <div className="rn-testimonial-content text-left">
                                            <div className="inner">
                                                <p>Игра крутая, топовая, лучшая, самая интересная игра из всех змеек, но есть несколько замечаний. Когда собираешь достижения, то в 3-4 раз когда ты их собираешь высвечивается что ты получил достижения, но собрать ты их не можешь и приходится перезаходить в игру.</p>
                                            </div>
                                            <div className="author-info">
                                                <h6><span>Asrafica95 </span> - отзыв с Google Play</h6>
                                            </div>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                        <div className="rn-testimonial-content text-left">
                                            <div className="inner">
                                                <p>Все исправно работает игра супер!!! Спасибо)</p>
                                            </div>
                                            <div className="author-info">
                                                <h6><span>Jannin </span> - отзыв с AppStore</h6>
                                            </div>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                        <div className="rn-testimonial-content text-left">
                                            <div className="inner">
                                                <p>Игра СУ-ПЕ-Р! Я играла раньше и тогда не было автопилота, а сейчас он есть, и это +. Так же появились питомцы, которых тоже не было. В самом то деле советую скачать. Можно играть за жужу (божью коровку) что тоже интересно как и за змею. Можно играть с друзьями, что круто. И множество разных скинов!</p>
                                            </div>
                                            <div className="author-info">
                                                <h6><span>Snake777 </span> - отзыв с Google Play</h6>
                                            </div>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                        <div className="rn-testimonial-content text-left">
                                            <div className="inner">
                                                <p>Самая крутая игра про змей видно что разработчики старались. Ещё круто что можно летать за жужу и есть авто управление (включается автоматически просто надо убрать руки с джойстика и черес 3 секунды он активируется). Крутая тема что решили добать слизней, баблов и жуж.</p>
                                            </div>
                                            <div className="author-info">
                                                <h6><span>Alice </span> - отзыв с AppStore</h6>
                                            </div>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                        <div className="rn-testimonial-content text-left">
                                            <div className="inner">
                                                <p>Игра более-менее интересная, но вот что огорчает так это то что со временем твоё звание пропадает. Дапустим достиг звания "Маркиз" и через несколько дней оно исчезнет. Очень хочется чтобы это убрали. А так игра очень хорошая.</p>
                                            </div>
                                            <div className="author-info">
                                                <h6><span>Orthi-1002 </span> - отзыв с Google Play</h6>
                                            </div>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                        <div className="rn-testimonial-content text-left">
                                            <div className="inner">
                                                <p>Здравствуйте разработчики этой игры! Игра классная, хочеться играть вечно. Минусы отсутствуют. Не стоит топить игру на дно из за памяти на телефоне. Игра однозначто зарабатывает 10/10. Жаль что нет такой оценки.</p>
                                            </div>
                                            <div className="author-info">
                                                <h6><span>Jaffin </span> - отзыв с Google Play</h6>
                                            </div>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                        <div className="rn-testimonial-content text-left">
                                            <div className="inner">
                                                <p>Игра очень классная, всем советую! Часто выходят обновления. (Я играю с компьютера). Иногда подлагивает, но дело в моем интернете. Играю в нее уже где то 3 или 2 года. Игра топ!</p>
                                            </div>
                                            <div className="author-info">
                                                <h6><span>Oleg81 </span> - отзыв с Google Play</h6>
                                            </div>
                                        </div>
                                    </TabPanel>
                                </div>
                                <div className="col-lg-6 mt_md--40 mt_sm--40">
                                    <TabList className="testimonial-thumb-wrapper">
                                        <Tab>
                                            <div className="testimonial-thumbnai">
                                                <div className="thumb">
                                                    <img src="/assets/images/client/testimonial-1.jpg"/>
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab>
                                            <div className="testimonial-thumbnai">
                                                <div className="thumb">
                                                    <img src="/assets/images/client/testimonial-2.jpg"/>
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab>
                                            <div className="testimonial-thumbnai">
                                                <div className="thumb">
                                                    <img src="/assets/images/client/testimonial-3.jpg"/>
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab>
                                            <div className="testimonial-thumbnai">
                                                <div className="thumb">
                                                    <img src="/assets/images/client/testimonial-4.jpg"/>
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab>
                                            <div className="testimonial-thumbnai">
                                                <div className="thumb">
                                                    <img src="/assets/images/client/testimonial-5.jpg"/>
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab>
                                            <div className="testimonial-thumbnai">
                                                <div className="thumb">
                                                    <img src="/assets/images/client/testimonial-6.jpg"/>
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab>
                                            <div className="testimonial-thumbnai">
                                                <div className="thumb">
                                                    <img src="/assets/images/client/testimonial-7.jpg"/>
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab>
                                            <div className="testimonial-thumbnai">
                                                <div className="thumb">
                                                    <img src="/assets/images/client/testimonial-8.jpg"/>
                                                </div>
                                            </div>
                                        </Tab>
                                    </TabList>
                                </div>
                            </div>
                        </Tabs>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default Testimonial;