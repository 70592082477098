import React, { Component } from "react";
import PortfolioGalleryPopupNoTimeForDragons from "../../../elements/portfolio/PortfolioGalleryPopupNoTimeForDragons";
import {FiChevronsRight} from "react-icons/all";


class AboutNoTimeForDragons extends Component{
    render(){
        let title = 'A Farewell to Dragons',
        description = 'A Farewell to Dragons is an action-RPG computer game in the steampunk style, created by Arise (development) and KranX Productions (production) based on the eponymous works of Nick Perumov and Sergei Lukianenko. It was published by 1C, and the SkyFallen graphics engine was used (“Blood Magic").',
        description2 = '';

        return(
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-flex-start">
                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/about_kranx_notime_for_dragons.jpg" alt="A Farewell to Dragons"/>
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        {/*<div className="icon">*/}
                                        {/*</div>*/}
                                        <h2 className="title">{title}</h2>
                                        <div className='description2'>
                                            <div className='item'>
                                                <div className='item-title'>Year:</div>
                                                <div>2007</div>
                                            </div>
                                            <div className='item'>
                                                <div className='item-title'>Platform:</div>
                                                <div>PC</div>
                                            </div>
                                            <div className='item'>
                                                <div className='item-title'>Genre:</div>
                                                <div>Action-RPG</div>
                                            </div>
                                        </div>
                                        <p className="description">{description}</p>
                                        <p className="description">{description2}</p>
                                        <div className="purchase-btn mb--30">
                                            <a href="https://ru.wikipedia.org/wiki/%D0%9D%D0%B5_%D0%B2%D1%80%D0%B5%D0%BC%D1%8F_%D0%B4%D0%BB%D1%8F_%D0%B4%D1%80%D0%B0%D0%BA%D0%BE%D0%BD%D0%BE%D0%B2_(%D0%BA%D0%BE%D0%BC%D0%BF%D1%8C%D1%8E%D1%82%D0%B5%D1%80%D0%BD%D0%B0%D1%8F_%D0%B8%D0%B3%D1%80%D0%B0)" className="btn-transparent" target="_blank" rel="noopener noreferrer">Description on Wikipedia <FiChevronsRight/></a>

                                        </div>
                                        <PortfolioGalleryPopupNoTimeForDragons/>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default AboutNoTimeForDragons;