import React, {Component} from "react";
import {FiChevronsRight} from "react-icons/all";

class AboutGeneral extends Component {
    render() {
        let title = 'General',
            description = '',
            description2 = '';

        return (
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-flex-start">
                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/about_katauri_general.jpg"
                                         alt="General"/>
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        {/*<div className="icon">*/}
                                        {/*</div>*/}
                                        <h2 className="title">{title}</h2>
                                        <div className='description2'>
                                            <div className='item'>
                                                <div className='item-title'>Year:</div>
                                                <div>2000</div>
                                            </div>
                                            <div className='item'>
                                                <div className='item-title'>Platform:</div>
                                                <div>PC</div>
                                            </div>
                                            <div className='item'>
                                                <div className='item-title'>Publisher:</div>
                                                <div>NewGame Software</div>
                                            </div>
                                            <div className='item'>
                                                <div className='item-title'>Genre:</div>
                                                <div>Turn-based strategy</div>
                                            </div>
                                            {/*<div className='item'>*/}
                                            {/*    <div className='item-title'>Ratings:</div>*/}
                                            {/*    <div>*/}
                                            {/*        <img width="20px" src="/assets/images/icons/ico-metacritic.png"*/}
                                            {/*             alt="metacritic logo"/>*/}
                                            {/*        <span>Metascore 84 / 100,</span>*/}
                                            {/*        <div>User Score 8.9 / 10</div>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                            {/*<div className='item'>*/}
                                            {/*    <div className='item-title'>Awards:</div>*/}
                                            {/*    <div>*/}
                                            {/*        <img width="20px" src="/assets/images/icons/ico-cup.png" alt="cup"/>*/}
                                            {/*        <span>#46 Best PC Game of 2008</span>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                        </div>
                                        {/*<p className="description">{description}</p>*/}
                                        {/*<p className="description">{description2}</p>*/}
                                        <div>
                                            <p className="description">
                                                "General" is the first turn-based strategy game crafted and coded by <a
                                                className=""
                                                href="https://ru.wikipedia.org/wiki/%D0%93%D1%83%D1%81%D0%B0%D1%80%D0%BE%D0%B2,_%D0%94%D0%BC%D0%B8%D1%82%D1%80%D0%B8%D0%B9_%D0%92%D0%BB%D0%B0%D0%B4%D0%B8%D0%BC%D0%B8%D1%80%D0%BE%D0%B2%D0%B8%D1%87"
                                                target="_blank" rel="noopener noreferrer">Dmitry Gusarov</a>, and it
                                                served as the foundation for the establishment of the NewGame Software
                                                company (Elemental Games). This game functions as a state simulator,
                                                complete with economic and strategic components. As the player, you must
                                                cultivate crops and feed your peasants, develop science, and train your
                                                army to succeed. Your ultimate objective is to conquer rival states.
                                            </p>
                                            <p className="description">
                                                One unique aspect of the game is its support for multiplayer
                                                functionality over the Internet. An interesting feature is that players
                                                can make moves simultaneously, and they may choose to form alliances to
                                                engage in group battles.
                                            </p>
                                            <p className="description">
                                                The game is over 20 years old but it still thrives and holds tournaments
                                                on <a href="http://akasoft.genliga.ru/index_r.php" target="_blank"
                                                      rel="noopener noreferrer">the General League website</a>. Welcome!
                                            </p>
                                        </div>
                                        <div className="purchase-btn mb--30 mt--30">
                                            <a href="https://ru.wikipedia.org/wiki/%D0%93%D0%B5%D0%BD%D0%B5%D1%80%D0%B0%D0%BB_(%D0%B8%D0%B3%D1%80%D0%B0)"
                                               className="btn-transparent" target="_blank" rel="noopener noreferrer">Description
                                                on Wikipedia <FiChevronsRight/></a>
                                        </div>
                                        <div className="purchase-btn mb--30">
                                            <a href="http://akasoft.genliga.ru/index_r.php"
                                               className="btn-transparent" target="_blank" rel="noopener noreferrer">General
                                                game website <FiChevronsRight/></a>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default AboutGeneral;