import React, { Component } from "react";
import PortfolioGalleryPopupKingsBountyLegend from "../../../elements/portfolio/PortfolioGalleryPopupKingsBountyLegend";
import {FiChevronsRight} from "react-icons/all";


class AboutKingsBountyLegend extends Component{
    render(){
        let title = 'King\'s Bounty: The Legend',
        description = 'An epic adventure that embodies the classic essence of King\'s Bounty, combining the thrill of role-playing with intricate and captivating tactical battles.',
        description2 = 'The hero (warrior, mage, or paladin) and a whole army of his faithful companions travel across a vast continent. As a royal treasure hunter, your daily tasks will include elevating or toppling kings, freeing princesses from dragon captivity, searching for pirate treasures, taming obstinate ancient artifacts, and confronting those vying for global supremacy. Be prepared for a challenging journey.';

        return(
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-flex-start">
                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/about_katauri_kings_bounty_legend.jpg" alt="King`s Bounty Legend Game"/>
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        {/*<div className="icon">*/}
                                        {/*</div>*/}
                                        <h2 className="title">{title}</h2>
                                        <div className='description2'>
                                            <div className='item'>
                                                <div className='item-title'>Year:</div>
                                                <div>2008</div>
                                            </div>
                                            <div className='item'>
                                                <div className='item-title'>Platform:</div>
                                                <div>PC, Steam</div>
                                            </div>
                                            <div className='item'>
                                                <div className='item-title'>Publisher:</div>
                                                <div>Atari, 1C Entertainment</div>
                                            </div>
                                            <div className='item'>
                                                <div className='item-title'>Genre:</div>
                                                <div>Tactical role-playing game</div>
                                            </div>
                                            <div className='item'>
                                                <div className='item-title'>Ratings:</div>
                                                <div>
                                                    <img width="20px" src="/assets/images/icons/ico-metacritic.png"
                                                         alt="metacritic logo"/>
                                                    <span>Metascore 79 / 100</span>
                                                    <div>User Score 8.7 / 10</div>
                                                    <div>GameRankings 83%</div>
                                                </div>
                                            </div>
                                            <div className='item'>
                                                <div className='item-title'>Awards:</div>
                                                <div style={{display: 'flex', flexDirection: 'column', gap: '5px'}}>
                                                    <div>
                                                        <img width="20px" src="/assets/images/icons/ico-cup.png" alt="cup"/>
                                                        <span>#46 Best PC Game of 2008</span>
                                                    </div>
                                                    <div>
                                                        <img width="40px" src="/assets/images/icons/kri_logo.jpg"
                                                             alt="logo"/>
                                                        <span>KRI Awards: </span>
                                                        <div><i>"Press Award":</i> <a className="" href="https://ru.wikipedia.org/wiki/%D0%93%D1%83%D1%81%D0%B0%D1%80%D0%BE%D0%B2,_%D0%94%D0%BC%D0%B8%D1%82%D1%80%D0%B8%D0%B9_%D0%92%D0%BB%D0%B0%D0%B4%D0%B8%D0%BC%D0%B8%D1%80%D0%BE%D0%B2%D0%B8%D1%87" target="_blank" rel="noopener noreferrer">Dmitry Gusarov</a></div>
                                                        <div><i>"Audience Choice Award"</i></div>
                                                        <div><i>"Best Game Design"</i></div>
                                                        <div><i>"Best Role-Playing Game"</i></div>
                                                        <div><i>"Best Game"</i></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <p className="description">{description}</p>
                                        <p className="description">{description2}</p>
                                        <div className="purchase-btn mb--30">
                                            <a href="https://store.steampowered.com/app/25900/Kings_Bounty_The_Legend/"
                                               className="btn-transparent" target="_blank" rel="noopener noreferrer">Available on STEAM <FiChevronsRight/></a>
                                        </div>
                                        <div className="purchase-btn mb--30">
                                            <a href="https://ru.wikipedia.org/wiki/King%E2%80%99s_Bounty._%D0%9B%D0%B5%D0%B3%D0%B5%D0%BD%D0%B4%D0%B0_%D0%BE_%D1%80%D1%8B%D1%86%D0%B0%D1%80%D0%B5"
                                               className="btn-transparent" target="_blank" rel="noopener noreferrer">Description on Wikipedia <FiChevronsRight/></a>
                                        </div>
                                        <div className="videos mb--30">
                                            <div className="video">
                                                <video controls
                                                       src="https://cdn.cloudflare.steamstatic.com/steam/apps/900742/movie480.webm?t=1447351653"
                                                       poster="/assets/images/preview/video_preview_katauri_kings_bounty_legend.jpg"
                                                />
                                            </div>
                                        </div>
                                        <PortfolioGalleryPopupKingsBountyLegend/>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default AboutKingsBountyLegend;