import React, { Component } from "react";
import PortfolioGalleryPopupMusaic from "../../../elements/portfolio/PortfolioGalleryPopupMusaic";
import {FiChevronsRight} from "react-icons/all";

class AboutMusaic extends Component{
    render(){
        let title = 'MusaicBox',
        description = 'Musaic Box is a unique game that involves collecting musical segments to form complete compositions. The player must uncover hidden notes within the home of an antique musical instrument collector to reassemble the melodies',
        description2 = 'The game "Musaic Box" was a finalist in the IGF Award 2009 Independent Games Festival in two categories: Excellence in Audio and Excellence in Design, taking first place in the latter. At the 7th International Game Developers Conference (KRI Awards) in Moscow, the game received the award as ""Best Casual Game"". The game was fully developed within KranX Productions.';

        return(
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-flex-start">
                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/about_kranx_musaic.jpg" alt="Musaic Game"/>
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        {/*<div className="icon">*/}
                                        {/*</div>*/}
                                        <h2 className="title">{title}</h2>
                                        <div className='description2'>
                                            <div className='item'>
                                                <div className='item-title'>Year:</div>
                                                <div>2008</div>
                                            </div>
                                            <div className='item'>
                                                <div className='item-title'>Platform:</div>
                                                <div>PC, Mobile, Steam</div>
                                            </div>
                                            <div className='item'>
                                                <div className='item-title'>Genre:</div>
                                                <div>Musical Puzzle</div>
                                            </div>
                                            <div className='item'>
                                                <div className='item-title'>Awards:</div>
                                                <div>
                                                    <div>KRI Awards 2009: "Best Casual Game"</div>
                                                    <div>GDC Independent Games Festival Award 2009: Excellence in Audio и Excellence in Design
                                                    </div>

                                                    <img width="" src="/assets/images/icons/rewards_musaic_box.jpg" alt="cup"/>
                                                </div>
                                            </div>
                                        </div>
                                        <p className="description">{description}</p>
                                        <p className="description">{description2}</p>
                                        <div className="purchase-btn mb--30">
                                            <a href="https://store.steampowered.com/app/29130/Musaic_Box/"
                                               className="btn-transparent" target="_blank" rel="noopener noreferrer">Available on STEAM <FiChevronsRight/></a>
                                        </div>
                                        <PortfolioGalleryPopupMusaic/>
                                        <div className="mt--50">
                                            <h3 className="mb--30">Reviews</h3>
                                            <div className="quotes">
                                                <figure className="quote">
                                                    <blockquote>
                                                        "I highly recommend you give Musaic Box a try. Both the puzzle and music game genres are filled with copycats and me-too games, but Musaic Box marries those two categories and creates something unique."
                                                    </blockquote>
                                                    <figcaption>
                                                        &mdash; <cite>IGN</cite></figcaption>
                                                </figure>
                                                <figure className="quote">
                                                    <blockquote>
                                                        "Music and spacial puzzling merge in a light symphony of creative gaming."
                                                    </blockquote>
                                                    <figcaption>
                                                        &mdash; <cite>148Apps</cite></figcaption>
                                                </figure>
                                            </div>

                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default AboutMusaic;