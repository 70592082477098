import React, {Component} from "react";
// import PortfolioGalleryPopupVangers from "../../../elements/portfolio/PortfolioGalleryPopupVangers";
import {FiChevronsRight} from "react-icons/all";

class AboutPerimeter extends Component {
    render() {
        let title = 'Perimeter',
            description = '"Perimeter" is a real-time strategy computer game created by the Kaliningrad-based company K-D LAB in 2004. The game boasts a distinctive feature that sets it apart from others in its genre — a destructible environment that allows players to utilize terrain features to gather resources for both offensive and defensive purposes. Additionally, players must navigate geological processes that erode the worlds they inhabit and defend against Скверна, hostile creatures that threaten their survival. Notable gameplay elements include the ability to instantly transform troops and a full implementation of positional warfare. "Perimeter" shares a fictional universe with another K-D LAB project, "Vangers," with the events of "Perimeter" taking place before those of "Vangers."',
            description2 = '';

        return (<React.Fragment>
            <div className="about-wrapper">
                <div className="container">
                    <div className="row row--35 align-items-flex-start">
                        <div className="col-lg-5 col-md-12">
                            <div className="thumbnail">
                                <img className="w-100" src="/assets/images/about/about_kranx_perimeter.jpg"
                                     alt="Perimeter Game"/>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-12">
                            <div className="about-inner inner">
                                <div className="section-title">
                                    {/*<div className="icon">*/}
                                    {/*</div>*/}
                                    <h2 className="title">{title}</h2>
                                    <div className='description2'>
                                        <div className='item'>
                                            <div className='item-title'>Year:</div>
                                            <div>2003-2004</div>
                                        </div>
                                        <div className='item'>
                                            <div className='item-title'>Platform:</div>
                                            <div>PC</div>
                                        </div>
                                        <div className='item'>
                                            <div className='item-title'>Genre:</div>
                                            <div>real-time strategy, RTS</div>
                                        </div>
                                        <div className='item'>
                                            <div className='item-title'>Awards:</div>
                                            <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                                                <div>
                                                    <img width="40px" src="/assets/images/icons/kri_logo.jpg"
                                                         alt="logo"/>
                                                    <span>KRI Awards: </span>
                                                    <div><i>"Best Technology" 2003</i></div>
                                                    <div><i>"Press Prize" 2003</i></div>
                                                    <div><i>"Best PC Game" 2004</i></div>
                                                    <div><i>"Best Game Design" 2004</i></div>
                                                </div>
                                                <div>
                                                    <span>Game World Navigator: </span>
                                                    <div><i>"Game World Navigator Choice"</i></div>
                                                    <div><i>"Best Experimental RTS of 2004"</i></div>
                                                </div>
                                                <div>
                                                    <span>Igromania: </span>
                                                    <div><i>"Editor's Choice"</i></div>
                                                    <div><i>"Most Unusual Game of 2004"</i></div>
                                                </div>
                                                <div>
                                                    <span>AG.ru: </span>
                                                    <div><i>"Our Choice"</i></div>
                                                </div>
                                                <div>
                                                    <span>PC Gamer (Russia): </span>
                                                    <div><i>"PC GAMER recommends"</i></div>
                                                </div>
                                                <div>
                                                    <span>PC Gamer (UK): </span>
                                                    <div><i>"Game of the Month"</i></div>
                                                </div>
                                                <div>
                                                    <span>Country of Games: </span>
                                                    <div><i>"Country of Games recommends"</i></div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <p className="description">{description}</p>
                                    <div className="purchase-btn mb--30">
                                        <a href="https://ru.wikipedia.org/wiki/%D0%9F%D0%B5%D1%80%D0%B8%D0%BC%D0%B5%D1%82%D1%80_(%D0%B8%D0%B3%D1%80%D0%B0)"
                                           className="btn-transparent" target="_blank" rel="noopener noreferrer">Description on Wikipedia <FiChevronsRight/></a>
                                    </div>
                                    {/*<div className="videos">*/}
                                    {/*    <div className="video">*/}
                                    {/*        <iframe width="" height="100%"*/}
                                    {/*                src="https://www.youtube.com/embed/7smUD83nb_8"*/}
                                    {/*                title="YouTube video player" frameBorder="0"*/}
                                    {/*                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"*/}
                                    {/*                allowFullScreen></iframe>*/}
                                    {/*    </div>*/}
                                    {/*    <div className="video">*/}
                                    {/*        <iframe width="560" height="315"*/}
                                    {/*                src="https://www.youtube.com/embed/Q85UNox8i0o"*/}
                                    {/*                title="YouTube video player" frameBorder="0"*/}
                                    {/*                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"*/}
                                    {/*                allowFullScreen></iframe>*/}
                                    {/*    </div>*/}
                                    {/*    <div className="video">*/}
                                    {/*        <iframe width="560" height="315"*/}
                                    {/*                src="https://www.youtube.com/embed/P9R7QJ5Sh1o"*/}
                                    {/*                title="YouTube video player" frameBorder="0"*/}
                                    {/*                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"*/}
                                    {/*                allowFullScreen></iframe>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    {/*<PortfolioGalleryPopupVangers/>*/}
                                    <div className="mt--50">
                                        <h3 className="mb--30">Reviews</h3>
                                        <div className="quotes">
                                            <figure className="quote">
                                                <blockquote>
                                                    "Perimeter is exceptional. The first real-time strategy game in the last couple of years that actually makes sense to play."
                                                </blockquote>
                                                <figcaption>
                                                    &mdash; <cite>PC Gamer (Russia)</cite></figcaption>
                                            </figure>
                                            <figure className="quote">
                                                <blockquote>
                                                    "Mysterious yet incredibly captivating game."
                                                </blockquote>
                                                <figcaption>
                                                    &mdash; <cite>Country of Games</cite></figcaption>
                                            </figure>
                                            <figure className="quote">
                                                <blockquote>
                                                    "Russian RTS stuns with beauty and innovation. We Want Inside this Perimeter."
                                                </blockquote>
                                                <figcaption>
                                                    &mdash; <cite>www.pc.ign.com</cite></figcaption>
                                            </figure>
                                            <figure className="quote">
                                                <blockquote>
                                                    "Perimeter takes the real-time strategy genre, infuses it with a wealth of novel concepts, and wraps it all up in a great-looking graphical package."
                                                </blockquote>
                                                <figcaption>
                                                    &mdash; <cite>www.gamerstemple.com</cite></figcaption>
                                            </figure>
                                            <figure className="quote">
                                                <blockquote>
                                                    "…a highly unusual game that aims to redefine the very „feel“ of real-time strategy."
                                                </blockquote>
                                                <figcaption>
                                                    &mdash; <cite>GameSpy</cite></figcaption>
                                            </figure>
                                        </div>

                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>)
    }
}

export default AboutPerimeter;