import React, { Component } from "react";
import {FiChevronsRight} from "react-icons/all";

class AboutMusicWars extends Component{
    render(){
        let title = 'Music Wars',
        description = 'Music Wars is a browser-based MMORPG developed by the company Neodinamika and published by Destiny Development. The game was launched in the fall of 2012 and has undergone over a decade of development at Neodinamika, continually being enriched with new mechanics and content. Music Wars takes the player on a journey through Sound City, where they must defend their musical preferences in intense player-vs-player battles. Its vibrant animated graphics and innovative combat system appeals to not only avid music enthusiasts but also to all gamers. With a huge following of millions of fans, this online game continues to captivate players and remains a highly successful business venture.',
        description2 = '';

        return(
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/about_neodinamika_musicwars.jpg" alt="Music Wars"/>
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        {/*<div className="icon">*/}
                                        {/*</div>*/}
                                        <h2 className="title">{title}</h2>
                                        <div className='description2'>
                                            <div className='item'>
                                                <div className='item-title'>Year:</div>
                                                <div>2012</div>
                                            </div>
                                            <div className='item'>
                                                <div className='item-title'>Platform:</div>
                                                <div>Web</div>
                                            </div>
                                            <div className='item'>
                                                <div className='item-title'>Genre:</div>
                                                <div>Free2Play MMORPG</div>
                                            </div>
                                            <div className='item'>
                                                <div className='item-title'>Audience:</div>
                                                <div>More than 10 million players</div>
                                            </div>
                                            {/*<div className='item'>*/}
                                            {/*    <div className='item-title'>Metacritic:</div>*/}
                                            {/*    <div>9999</div>*/}
                                            {/*</div>*/}
                                            <div className='item'>
                                                <div className='item-title'>Awards:</div>
                                                <div><img width="40px" src="/assets/images/icons/kri_logo.jpg" alt="cup"/>
                                                    <span>KRI Awards 2013: </span>
                                                    <div>"Best Browser Online Game" </div>
                                                </div>
                                            </div>
                                        </div>
                                        <p className="description">{description}</p>
                                        <p className="description">{description2}</p>
                                        <div className="purchase-btn">
                                            <a href="https://musicwars.ru/" className="btn-transparent" target="_blank" rel="noopener noreferrer">Music Wars game website <FiChevronsRight/></a>

                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default AboutMusicWars;