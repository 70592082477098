import React, { Component } from "react";
import {FiChevronsRight} from "react-icons/all";

class AboutTruthAboutNinth extends Component{
    render(){
        let title = 'Правда о девятой роте',
        description = '«Правда о девятой роте» — первая игра в уникальном документальном жанре; разработчик — Extreme Developers, при непосредственном участии фирмы «1C» и Дмитрия Пучкова, также известного как старший оперуполномоченный Goblin. «Правда о девятой роте» представляет интерактивную реконструкцию исторического сражения, разгоревшегося в ночь с 7 на 8 января 1988 года в южном Афганистане на высоте 3234 между бойцами легендарной девятой роты 345-го отдельного парашютно-десантного полка и отрядами душманов.',
        description2 = 'Игроку, оказавшемуся в эпицентре событий, предстоит самому увидеть битву и повторить подвиг тридцати девяти советских десантников, выступивших против нескольких сотен душманов. Шутер от первого лица предполагает последовательное перевоплощение то в одного, то в другого участника боя, что позволит не только прочувствовать и объективно представить случившееся, но и уяснить безжалостную логику войны, не прощающую даже мелких ошибок.';

        return(
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/about_kranx_truth_about_ninth.jpg" alt="Truth About Ninth Game"/>
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        {/*<div className="icon">*/}
                                        {/*</div>*/}
                                        <h2 className="title">{title}</h2>
                                        <p className="description">{description}</p>
                                        <p className="description">{description2}</p>
                                        {/*<div className="purchase-btn">
                                            <a href="https://royalquest.ru/" className="btn-transparent" target="_blank" rel="noopener noreferrer">Официальный сайт игры <FiChevronsRight/></a>

                                        </div>*/}
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default AboutTruthAboutNinth;