import React, { Component } from "react";
import {Link} from "react-router-dom";

class About extends Component{
    render(){
        let title = 'Neodinamika',
        description = '',
        description2 = '';


        return(
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/about_neodinamika.jpg" alt="About Neodinamika"/>
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        {/*<div className="icon">*/}
                                        {/*</div>*/}
                                        <h2 className="title">{title}</h2>
                                        <p className="description">
                                            Neodinamika is a successful international studio formed in 2011 by the merger of <Link  to="/about-katauri">Katauri Interactive</Link> and <Link to="/about-kranx">KranX Productions</Link>, which already had years of experience in creating widely recognized and brilliant games for various platforms.
                                        </p>

                                        <p className="description">Neodinamika's main focus is the development of top-notch cross-platform online games featuring impeccably crafted and finely tuned game design, along with seamless monetization integration.</p>
                                        {/*<div className="purchase-btn">
                                            <Link className="btn-transparent" to="/">PURCHASE IMROZ</Link>
                                        </div>*/}
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default About;