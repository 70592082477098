import React, { Component } from "react";
import {FiChevronsRight} from "react-icons/all";

class AboutCubium extends Component{
    render(){
        let title = 'Cubium',
        description = 'Cubium — это игра головоломка в невероятном мире чувств и эмоций, путешествуя по которому вам предстоит решать разнообразные загадки. В процессе игры вы столкнётесь с различными законами физики, используя которые нужно будет решить те или иные задачи. Всё это под невероятную, атмосферную музыку, написанную специально для Cubium.',
        description2 = 'Вам предстоит долгий путь по неизведанному, поглощенному тьмой миру,  во время которого  нужно находить и использовать рунические кубы различного свойства и предназначения.';

        return(
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/about_kranx_cubium.jpg" alt="Cubium Game"/>
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        {/*<div className="icon">*/}
                                        {/*</div>*/}
                                        <h2 className="title">{title}</h2>
                                        <p className="description">{description}</p>
                                        <p className="description">{description2}</p>
                                        {/*<div className="purchase-btn">
                                            <a href="https://royalquest.ru/" className="btn-transparent" target="_blank" rel="noopener noreferrer">Официальный сайт игры <FiChevronsRight/></a>
                                        </div>*/}
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default AboutCubium;